const AchievemnetData = [
    {
      id : 1,
      title: "30+",
      desc : "Countries Served",
      cardbg: "rgba(26, 182, 157, 0.07)",
      Image: "/greenico.svg",
    },
    {
        id : 2,
        title: "2000+",
        desc : "Students Enrolled",
        cardbg: "rgba(238, 74, 98, 0.07)",
        Image: "/userico.svg",
        },
    {
      id:3,
      title: "1000+",
      desc : "University Partners",
      cardbg: "rgba(70, 100, 228, 0.07)",
      Image: "/violetico.svg", },
    {
      id:4,
      title: "25000+",
      desc : "Expert Counselings",
      cardbg: "rgba(248, 148, 31, 0.07)",
      Image: "/yellowico.svg",  },
      
  ];
  
  export default AchievemnetData;