import React from "react";
import Review from "../components/Review";
import styles from "../modules/Review.module.css"; // Import your CSS module here
const testimonials = [
  
  {
    text: "Reflecting on my journey so far, I can confidently say that  Mavex Mentr has been the driving force behind my overseas education success. They didn't just guide me through paperwork; they sculpted an experience that has shaped me both academically and personally. The friendships, the knowledge, and the memories I've gained are all a testament to their dedication.",
    author: "Faris - Teesside university - IBM",
    backgroundImage:
      "https://drive.google.com/uc?export=view&id=1JHzrKX2uUASTO4bIwBVLtZ2WDNC-sCnE",
  },

  {
    text: "Throughout my time abroad, I never felt alone thanks to the continuous assistance from Mavex Mentr .  Whether it was helping me to choosing university , Interview training, finding accommodation, connecting me with fellow students, or addressing any challenges I faced, their support was unwavering. They genuinely cared about my well-being and success",
    author: "Ajmal Najeem-De Mont Fort University-Global MBA",
    backgroundImage:
      "https://drive.google.com/uc?export=view&id=1dt6v5uQVlt64-KNZpcjSOEJlcJo24Zbm",
      },

  {
    text: "Mavex made the process of studying in the UK so much smoother and stress-free. They,helped me with everything from choosing the right university and program to applying for my visa. I couldn't have done it without them!",
    author: "Amjath ",
    backgroundImage:
      "https://drive.google.com/uc?export=view&id=1iJHT8rPuo71EbWNVHpjqQODW2RQjfblM",
  },

  {
    text: "I cannot thank Mavex enough for their exceptional service. They were always available to answer my questions and address any concerns I had. All thanks to Mavex Mentr, I would thighly recommend anyone to choose Mavex for their educational journey. Special thanks to Shahanas itha for guiding me through.",
    author: "Krishnaswaroop",
    backgroundImage:
      "https://drive.google.com/uc?export=view&id=1l7d6c3aXu3IpD_UrXsf8UEvgBB5ZgJQy",
  },
  {
    text: "Mavex truly exceeded my expectations in terms of the level of support and guidance they provided. Their personalized approach and attention to detail made all the difference in my application process to Ain Shams University. Special thanks to Bisna ma’am for guiding me throughout the journey.",
    author: "Haneena",
    backgroundImage:
      "https://english.mathrubhumi.com/image/contentid/policy:1.5620455:1644481125/image.jpg?$p=0f6e831&f=16x10&w=856&q=0.8",
  },
];
const TestimonialCarousel = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        
        <p>Testimonials</p>
        <h1>Results<span className={styles.highlite}> speak</span> loudest</h1>
        <p>Check out what our students says</p>
      </div>
      <div className={styles.carousel}>
    <div className={styles.carouselContainer}>

      {testimonials.map((testimonial, index) => (
        <Review
          key={index}
          text={testimonial.text}
          author={testimonial.author}
          backgroundImage={testimonial.backgroundImage}
        />
      ))}
    </div>
  </div>
    </div>
   
  );
};

export default TestimonialCarousel;
