import React from 'react';
import styles from '../modules/Review.module.css'; // Import your CSS module here
const Review = ({ text, author, backgroundImage, isActive }) => {
  const nextLineStyle = {
    whiteSpace: 'pre-line',
  };

  return (
    <div className={styles.review} >

      <div
        className={styles.reviewBackground}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className={styles.overlay}>
        <div className={styles.overlayContent}>
          <p className={styles.text}>{text}</p>
          <p className={styles.author}>- {author}</p>
        </div>
      </div>
    </div>
  );
};

export default Review;
