import classes from "../modules/VideoBanner.module.css";
import { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import YouTube from "react-youtube";

function VideoBanner() {
  const [showVideo, setShowVideo] = useState(false);
  const [ref, inView] = useInView();
  const playerRef = useRef(null);

  useEffect(() => {
    if (inView) {
      setShowVideo(true);
    }
  }, [inView]);

  const opts = {
    height: "315",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div id="videobanner" className={classes.container}>
      <div className={classes.rightcontainer}>
        <div className={classes.textcontainer}>
          <p className={classes.desc}>App Showcase</p>
          <h1 className={classes.title}>
            Empowering Your Journey with{" "}
            <span className={classes.spanedtext}> Mavex </span> Mentr!
            <br />
          </h1>

          <div className={classes.videoplayer} ref={ref}>
            {showVideo && (
              <YouTube
                videoId="9fV-VrpUTdw?si=zEdlxRw5cW2kW3mK"
                opts={opts}
                ref={playerRef}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.leftcontainer}>
        <img
          src="/assets/aparna.png"
          alt="Mavex App"
          className={classes.image}
        />
      </div>
    </div>
  );
}

export default VideoBanner;
