import React from "react";
import "./index.css";
import App from "./App";
import ReactDom from "react-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from "react-router-dom";
const queryClient = new QueryClient();
ReactDom.render(
    <BrowserRouter>
   
   <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
    </BrowserRouter>,
  document.querySelector("#root")
);