import classes from "../modules/Achievements.module.css"

import AchievemnetInfo from "../models/AchievementData";
import CardView from "../ui/CardView";

function Achievements(){
    return (
        <div id="ourProcess" className={classes.container}> 
        <div className={classes.innertopcontainer}>

        </div>
        <div className={classes.innercontainer}>
         <ul className={classes.listcontainer}>
            { AchievemnetInfo.map((achievemnetData) => (
              <CardView 
              title={achievemnetData.title} 
              body={achievemnetData.desc} 
              bg={achievemnetData.cardbg} 
              img = {achievemnetData.Image}
              />
            ))}
            </ul>
         </div>
        </div>
      );
    }
export default Achievements;