const FaqData = [
  {
    id: 1,
    question: "Can language be a barrier?",
    answer: "Language can be a major concern, however, if you are going to an English-speaking country - you only have to take care of it.  You can improve your skills and learn the language which would be an addition to your time you stay in abroad.",
  },
  {
    id: 2,
    question: "How much does it cost?",
    answer: "In case you have enrolled in a foreign institution, you will be eligible to pay a tuition fee as well as one semester or full year fee. Apart from this, you will also be paying for your housing, food, and other essentials.",
  },
  {
    id: 3,
    question: "Where can I study?",
    answer: "It is certainly not an easy task to choose and pick out where you plan to study abroad. However, it all depends on either or your personal interests, the cost, career prospects you choose to. Choose the country that fulfills your study program needs along with your safety and employment prospects.",
  },
  {
    id: 4,
    question: "Who can study abroad?",
    answer: "Anyone who is ready to take a chance and take a leap of exposure and experiences, study abroad is the best choice for you.",
  },  {
    id: 5,
    question: "How do I save money when studying abroad?",
    answer: "Studying abroad can be expensive; there are many scholarships and financial aid services available for you to apply. In addition to that, you can",
  },  {
    id: 6,
    question: "Can I work while studying abroad?",
    answer: "Yes, you can! Studying abroad can be expensive and finding a job is a great way to help with those expenses. Possessing industry related experience would make your CV stand out and boost your employability after you graduate.",
  }
  
  
];

export default FaqData;
