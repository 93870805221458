// api.js
import axios from 'axios';
import { API_BASE_URL, CreateUserUrl } from './ApiEndpoints';

// const API_BASE_URL = 'https://api.learnyn.in';


const ApiServices = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchUserData = async (userId) => {
  const response = await ApiServices.get(`/users/${userId}`);
  return response.data;
};


export const submitEnquiry = async (requestData) => {
 try {
  console.log("request", requestData);
  const response = await ApiServices.post(CreateUserUrl, requestData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.status >= 200 && response.status < 300) {
    const userData = response.data;
    console.log("response", userData);
    return userData; // Return success data
  } else {
    throw new Error(`Server returned status ${response.status}`);
  }
} catch (error) {
  console.error("Request error:", error);
  throw error; // Re-throw the error to be caught in the calling component
}

};
