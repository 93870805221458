import React, { useState, useEffect, useRef } from "react";
import classes from "../modules/ProcessLevel.module.css";

const items = [
  {
    title: "Know your options",
    detail:
      "Discover the perfect destination, choose the right program, and navigate the practicalities of studying abroad. From financial considerations to cultural experiences, empower yourself to make informed decisions and embrace a transformative international education. Your adventure starts here.",
    image:
      "https://drive.google.com/thumbnail?id=1fBabRD5qfW4ELeplY9EYG3BN1CfxaVg7&sz=w1000",
  },
  {
    title: "Get expert counselling",
    detail:
      "Access personalized expert counseling to navigate your educational and career path with confidence and clarity. Our experienced advisors provide tailored guidance, helping you explore options, set goals, and create a successful roadmap for your future.",
    image:
      "https://drive.google.com/thumbnail?id=1aXHFwaGt6yZ1_nskLaepW8HZrBvPU5_I&sz=w1000",
  },
  {
    title: "Start your application",
    detail:
      "Embark on your academic adventure by taking the first step – submit your application now. Our straightforward process ensures that you're on your way to joining a vibrant community of learners, exploring new horizons, and shaping a brighter future.",
    image:
      "https://drive.google.com/thumbnail?id=1ymHgZ_7MExHxE6CPghDRXyR2KpSNGGXQ&sz=w1000",
  },
  {
    title: "Track your offer",
    detail:
      "Stay informed about your offer's progress with our user-friendly 'Track your offer' tool. Easily monitor updates, from application submission to final decision, ensuring you're always in the know.",
    image:
      "https://drive.google.com/thumbnail?id=1IjMZpbIDewDhkMl5f-y8n0cN1C7nJJro&sz=w1000",
  },
  {
    title: "Confirm your admission by paying a deposit",
    detail:
      "Secure your spot at your institution. Complete your admission process by paying a deposit, ensuring your place in the upcoming academic term. Act now to guarantee your enrollment and embark on a rewarding educational journey with us.",
    image:
      "https://drive.google.com/thumbnail?id=10tajkuVNajSreyOeAME6hChdph0da1eE&sz=w1000",
  },
  {
    title: "Get help with test and interview preperation",
    detail:
      "Excel in tests and interviews with expert assistance. Enhance your skills, boost confidence, and prepare effectively for success. Our support ensures you're ready to shine in any testing or interview scenario.",
    image:
      "https://drive.google.com/thumbnail?id=1O1h89UkCLfdzL_nmoSlxa7KmjwWaHoOx&sz=w1000",
  },
  {
    title: "Prepare your finances",
    detail:
      "Get ready for your study abroad journey by organizing your finances. Learn how to budget, explore scholarships, and plan for expenses, ensuring a smooth and stress-free experience as you pursue your education overseas.",
    image:
      "https://drive.google.com/thumbnail?id=1s33H62QlD5mYk7r1lH1rO6pXrl-64anZ&sz=w1000",
  },
  {
    title: "Get help with Visa, travel and accomodation",
    detail:
      "Ease your journey with expert guidance. We provide assistance for your visa application, travel arrangements, and comfortable accommodations, ensuring a seamless experience as you pursue your dreams abroad.",
    image:
      "https://drive.google.com/thumbnail?id=1hfGRzX4XdN-6GrH5zsOihj3KHxAhQHEW&sz=w1000",
  },
  {
    title: "Hurray!!! all set to fly",
    detail:
      "Embracing excitement and new beginnings, 'Hurray!!! All Set to Fly' captures the anticipation of embarking on a thrilling journey. This title reflects the joyous spirit of stepping into the unknown, ready to explore, learn, and make unforgettable memories. Get ready to soar towards new horizons",
    image:
      "https://drive.google.com/thumbnail?id=1XKDyFPALRVw0rqolj-I3FoZm2189hE84&sz=w1000",
  },
];

function ProcessLevel() {
  const [fillPercentage, setFillPercentage] = useState(0);
  const verticalListRef = useRef(null);

  const handleScroll = () => {
    if (verticalListRef.current) {
      const verticalListHeight = verticalListRef.current.offsetHeight;
      const viewportHeight = window.innerHeight;
      const maxScroll = verticalListHeight - viewportHeight;
      const currentScroll = window.scrollY - 600;
      const newFillPercentage = (currentScroll / maxScroll) * 100;
      setFillPercentage(Math.min(newFillPercentage, 100));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {" "}
      <div className={classes.title}>
      <div className={classes.textcontainer}>
          <p className={classes.desc}>What we do?</p>
          <h1 className={classes.titleText}>Our <span className={classes.highlite}> admission</span> process</h1>
       </div>
      </div>
      <div className={classes.verticallist} ref={verticalListRef}>
        {items.map((item, index) => (
          <div className={classes.verticallistitem} key={index}>
            <div className={classes.info}>
              <div className={classes.infoTxt}>
                <h1>{item.title}</h1>
                <p>{item.detail}</p>
              </div>
            </div>
            <img  className={classes.timelineimg} src="/timeline.svg" alt="timeline" />
            <img className={classes.sideimage}
              src={item.image}
              alt={item.title}
            />
          </div>
        ))}

        <div className={classes.fillContainer}>
          <div
            className={classes.fillProgress}
            style={{ height: `${fillPercentage}%` }}
          ></div>
          <div className={classes.arrowContainer}>
            <img className={classes.arrow} src="/down.svg" alt="arrow" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProcessLevel;
