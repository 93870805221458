import { useState, useEffect } from "react";
import axios from "axios";
import UniversityCard from "../ui/UniversityCard";
import classes from "../modules/TopUniversity.module.css";

function TopUniversities() {
  const [universities, setUniversities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //write api call here
      try {
        const response = await axios.get(
          "https://api.learnyn.in/university/getall"
        );
        const filteredData = response.data.responsedata.slice(0, 10);
        setUniversities(filteredData);
      } catch (err) {
        console.error("Error in retreiving country call :" + err);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="University" className={classes.container}>
      <div className={classes.innertopcontainer}>
        <div>
          <p>TOP UNIVERSITIES</p>
          <div className={classes.titlehead}>
          <h1 >
            Browse top <span className={classes.highlite}>universities</span>
          </h1>
          </div>
          <p>Select from a wide range of universities across globe</p>
        </div>
      </div>
      <div className={classes.innercontainer}>
        {universities.map((university) => (
          <UniversityCard universityObj={university} />
        ))}
      </div>
    </div>
  );
}

export default TopUniversities;
