import classes from "../modules/MobileApp.module.css";

import {
  GooglePlayButton,
  ButtonsContainer,
  AppStoreButton,
} from "react-mobile-app-button";

function MobileApp() {
  const APKUrl =
    "https://play.google.com/store/apps/details?id=io.learnyn.mavexmentr";
  const iOSUrl = "https://apps.apple.com/in/app/mavex-mentr/id6451036664";

  return (
    <div id="mobileApp" className={classes.container}>
      <div className={classes.rightcontainer}>
        <div className={classes.textcontainer}>
          <p className={classes.desc}>Experience the Future</p>
          <h1 className={classes.title}>
            Download <span className={classes.spanedtext}> Mavex Mentr </span>{" "}
            Mobile App Now!
            <br />
          </h1>

          <p className={classes.desc}>
            Embrace innovation with the Mavex Mentr Mobile App. Elevate your
            journey to success. Streamline consultations, course selection, and
            more. Download today for a transformative experience.
          </p>
          <div className={classes.buttoncontainer}>
            
              <GooglePlayButton
                url={APKUrl}
                theme={"dark"}
                className={"custom-style"}
              />
              <AppStoreButton
                url={iOSUrl}
                theme={"dark"}
                className={"custom-style"}
              />
         
          </div>
        </div>
      </div>
      <div className={classes.leftcontainer}>
        <img src="/assets/mavexscreen.jpg" alt="Mavex App" />
      </div>
    </div>
  );
}

export default MobileApp;
