import classes from "../modules/AboutUs.module.css";
import TruncatedParagraph from "../ui/TrucatedParagraph";

function AboutUs() {
  const aboutUsText ="Mavex Mentr is an Edu- tech platform & one stop solution  where you will have an idea on where and what to acquire from us to become yourself a wholesome individual. The priority is always closely connected to the bridging of academic necessity with that of the thought to academically elevate in all dimensions.  Education is an enlightening process and we assure you the guidance from all dimensions. We are flexible in rendering you the most updated options and opportunities regarding national as well as international courses beginning from the course selection, guidance to opt the eligible University, all the official part of it that come upto visa support, documentation, accommodation, post admission services to the assistance to languages of various lines. Persistence is the key and undoubtedly,we have had the interactions with the individuals who are preferably excelling in their respective spheres. We would like to give you our face of the leading platform that can cater you with your national and international education pathways. Our services can be fetched through online and offline and we assure you the guarantee to vision your happiness to the next step."
  return (
    <div id="aboutUs" className={classes.container}>
     
      <div className={classes.rightcontainer}>
        <div className={classes.textcontainer}>
          <p className={classes.desc}>Who we are?</p>
          <h1 className={classes.title}>
            We are transcending borders and
            <span className={classes.spanedtext}> expanding</span> our global
            reach.<br></br>
          </h1>
          <TruncatedParagraph className={classes.desc} text={aboutUsText} maxWords={60} />
        
        </div>
      </div>
      <div className={classes.leftcontainer}>
        <img
          src="/assets/reach.png"
          alt="Aboutus"
          className={classes.image}
        />
      </div>
    </div>
  );
}

export default AboutUs;
