import AboutUs from "../components/AboutUs";
import Countries from "../components/Countries";
import Header from "../components/Header";
import HeroArea from "../components/HeroArea";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import TimeLine from "../components/TimeLine";
import TopUniversities from "../components/TopUniversities";

import { Route } from "react-router-dom";
import TopCountries from "../components/TopCountries";
import ProcessLevel from "../components/ProcessLevel";
import Reels from "../components/Reels";
import FooterAnimation from "../components/FooterAnimation";
import Achievements from "../components/Achievements";

import TestimonialCarousel from "../ui/TestimonialCarousel";
import MobileApp from "../components/MobileApp";
import VideoBanner from "../components/VideoBanner";
import EnquiryForm from "../components/EnquiryForm";

function LayoutCard(props) {
  return (
    <div>
      <Header />
      <HeroArea />
      <ProcessLevel />
      <VideoBanner />
      <TopCountries />
      <EnquiryForm />
      <AboutUs />
      <Achievements />
      <TopUniversities />
      <Reels />
      <Faq />
      <MobileApp />
      <TestimonialCarousel />
      <Footer />
      <FooterAnimation />
     
      {props.children}
    </div>
  );
}

export default LayoutCard;
