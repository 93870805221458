import classes from "../modules/Privacy.module.css";
function Privacy() {
  return (
    <>
      <div>
          <img  className={classes.logo} src="mainlogo.svg" alt="hero image" />
          </div>
   
    <div className={classes.container}>
       
      <p>
        <i>
          PLEASE READ THE PRIVACY POLICY CAREFULLY BEFORE AVAILING SERVICES ON
          THIS PLATFORM. AVAILING SERVICES THROUGH THIS PLATFORM SIGNIFIES YOUR
          ACCEPTANCE OF THE TERMS OF USE AND OUR PRIVACY POLICY AND YOUR CONSENT
          AND AGREEMENT TO BE LEGALLY BOUND BY THE SAME.
        </i>
        
      </p>

      <p>
        <i>
          IF YOU DO NOT AGREE WITH THE PRIVACY POLICY, PLEASE DO NOT ACCESS THIS
          PLATFORM FOR ANY SERVICES.
        </i>
      </p>

      <p>
        <i>
          IF YOU CONTINUE TO ACCESS THE PLATFORM, IT IS DEEMED THAT YOU HAVE
          PROVIDED YOUR UNCONDITIONAL CONSENT AND AGREEMENT TO Mavex Mentr AS
          PROVIDED UNDER SECTION 43A AND SECTION 72A OF INFORMATION TECHNOLOGY
          ACT, 2000.
        </i>
      </p>

      <br />
      <h4>I. INTRODUCTION </h4>

      <p>
        a. <a href="https://mavexmentr.com/">www.mavexmentr.com </a>(hereinafter
        referred to as “Mavex Mentr”, “Website” or “Platform”, which term includes
        the website, sub-domains of the website, software, mobile application(s)
        or any other medium through which Mavex Mentr may provide its services) is
        owned and operated by M/s Mavex Mentr Portal  (“Mavex Mentr”), a private limited
        company incorporated under the laws of India and having
        its registered office at Metropillar No. 328, Pathicheril Enclave, 2A, Changampuzha Nagar,
         Kochi, Kerala 682033.
      </p>
      <p>
        b. Mavex Mentr is a new age social platform that provides a multilateral
        user generated communication channel between educational institutions,
        students and other stakeholders of this coveted industry. While pursuing
        the vision to help the students find the right courses and institutions
        based on their learning inclination and calibre, we also aim to help the
        institutions find the right talent for their programs by curating a
        refined bulk of profiles with necessary checks and balances{" "}
      </p>
      <p>
        c. In the course of and for providing its services, Mavex Mentr may require
        some personal information, such as users’ name, e-mail id, contact
        number, permanent address, etc. Mavex Mentr only uses such information to
        verify and authenticate users’ identity and may have to provide limited
        personal information, including name and contact number, to its
        registered business partners for smooth and uninterrupted services.
        However, Mavex Mentr does not in any way commercially exploit or share with
        any unaffiliated third parties any personal information of its users.{" "}
      </p>
      <p>
        d. For the purpose of this Privacy Policy, wherever the context so
        requires, "you" or "User" shall mean any natural or legal person who has
        agreed to use the Platform to avail Mavex Mentr’s services as stipulated in
        the terms of use, whether registered or not, including Users whose
        accounts are about to expire;
      </p>
      <p>
        e. By using the Services, You agree to the practices and policies
        outlined in this Privacy Policy and You hereby consent to the
        collection, use, and sharing of Your information as described in this
        Privacy Policy. If You do not agree with this Privacy Policy, You should
        not use the Services. If You use the Services on behalf of someone else
        (such as Your child) or an entity (such as Your employer), You represent
        that You are authorized by such individual or entity to accept this
        Privacy Policy on such individual’s or entity’s behalf.
      </p>
      <p>
        f. This Privacy Policy is in compliance with all the relevant Laws,
        rules and regulations at the time being in force, including but not
        limited to the Information Technology Act, 2000; and the Information
        Technology (Reasonable Security Practices and Procedures and Sensitive
        Personal Information) Rules, 2011 (hereinafter referred to as the “SPI
        Rules”), and the Information Technology (Intermediaries Guidelines)
        Rules, 2011 (hereinafter referred to as the “IG Rules”).
      </p>
      <p>
        g. This Privacy Policy governs aspects which include but not limited to
        the type of information collected by Mavex Mentr, including Personal
        Information and Sensitive Personal Information (hereinafter collectively
        referred to as “Information”), the purpose, means and modes of usage and
        collection of such Information and disclosure of such Information.
      </p>
      <br />
      <h4>
        II. COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION AND OTHER
        INFORMATION
      </h4>
      <p>
        a. When you use the Platform, we collect and store your personal
        information which is provided by you from time to time. Our primary goal
        in doing so is to provide you with a safe, efficient, smooth and
        customized experience. This allows us to provide services and features
        that most likely meet your needs, and to customize the Platform to make
        your experience safer and easier. More importantly, while doing so we
        collect personal information from you that we consider necessary for
        achieving this purpose and for providing our services. We do not collect
        sensitive personal information such as biometric information, physical
        and physiological condition, etc.
      </p>
      <p>
        b. To use the services of the Platform, you must register with Mavex Mentr.
        Once you give us your personal information, you are not anonymous to us.
        Where possible, we indicate which fields are required and which fields
        are optional. You always have the option to not provide information by
        choosing not to use a particular service or feature on the Platform.
        However, you will need to create a personal profile in order to avail
        the Services. The details you include in your personal profile shall be
        viewable by us and by our business partners and other persons with whom
        you may voluntarily chose to share your personal profile, in order to
        provide smooth services and to ensure authenticity and prevent fraud and
        mischief.{" "}
      </p>
      <p>
        c. We use data collection devices such as “log data” and "cookies" on
        certain pages / in certain parts of the Platform to help analyse our web
        page flow, measure promotional effectiveness, and promote trust and
        safety. “Log Data” is information or data you’re your browser sends to
        us and "Cookies" are small files placed on your hard drive that assist
        us in providing our services. We offer certain features that are only
        available through the use of a "cookie". We also use cookies to allow
        you to enter your password less frequently during a session. Cookies can
        also help us provide information that is targeted to your interests.
        Most cookies are "session cookies," meaning that they are automatically
        deleted from your hard drive at the end of a session. You are always
        free to decline our cookies if your browser permits, although in that
        case you may not be able to use certain features of the Platform and you
        may be required to re-enter your password more frequently during a
        session.{" "}
      </p>
      <p>
        d. Additionally, you may encounter "cookies" or other similar devices on
        certain pages of the Platform that are placed by third parties. We do
        not control the use of cookies placed by third parties.
      </p>
      <p>
        e. If you choose to post messages on our message boards or leave
        feedback, we will collect the information you provide to us. We retain
        this information as necessary to resolve disputes, provide customer
        support and troubleshoot problems as permitted by law.
      </p>
      <p>
        f. If you send us personal correspondence, such as emails or letters, or
        if other users or third parties send us correspondence about your
        activities with respect to the Platform, we may collect such information
        into a file specific to you.
      </p>
      <p>
        g. We collect personally identifiable information (email address, name,
        phone number, permanent address etc.) from you when you set up an
        account with us. We may share your personal information with our business partners, 
        or to our employees, agents, consultants or with trusted third parties, on a
        purely ‘need-to-know’ basis and will treat such information as
        confidential information.
      </p>
      <p>
        h. We may collect non-personal identification information about users
        whenever they interact with the Platform. Non-personal identification
        information may include the browser name, the type of computer and
        technical information about Users’ means of connection to our Site, such
        as the operating system and the Internet service provider utilized and
        other similar information, including Media Access Control (MAC) address,
        screen resolution, operating system version, Internet browser type and
        version, time and duration of access to the Platform or any part
        thereof. We may collect similar information, such as your device type
        and identifier, if you access the Platform through a mobile device.
      </p>
      <p>
        i. Your personal information may be stored and processed in any country
        where we have facilities or service providers, and by using the
        Platform, or by providing consent to us (where required by law), you
        agree to the transfer of information to countries outside of your
        country of residence, which may provide for different data protection
        rules than in your country.
      </p>
      <br />
      <h4>III. USE OF PERSONAL INFORMATION</h4>
      <p>
        a. We use your personal information to resolve
        disputes; troubleshoot problems; help promote a safe service; measure
        consumer interest in specific products and services; customize your
        experience; detect and protect us against error, fraud and other
        criminal activity; enforce our terms and conditions; and as otherwise
        described to you at the time of collection.
      </p>
      <p>
        b. In our efforts to continually improve our product and service
        offerings, we collect and analyse demographic and profile data about our
        users' activity on our Website. Mavex Mentr reserves the right to provide
        aggregate demographic information of its members / user base to
        advertisers, organizations / associations, governmental authorities /
        departments, third party service providers and others in furtherance of
        the objects of Mavex Mentr and in the interests of promoting transparent and
        consumer / user – driven practices and policies. We may share some of
        your personal information to trusted third parties on the Platform in
        order to ensure transparency, authenticate and verify the information
        provided to us or collected by us.
      </p>
      <p>
        c. We may employ third-party companies and individuals for the following
        activities:
        <ul class="list-1">
          <li>To facilitate our services;</li>
          <li>To provide the services on our behalf;</li>
          <li>To perform service-related services; or</li>
          <li>To assist us in analyzing how our service is used.</li>
        </ul>
        These third parties may have access to your personal information for the
        purposes mentioned above. However, they shall be obligated not to
        disclose or use such information for any other purpose.
      </p>
      <p>
        d. We do not share sensitive personal information of individual Users
        with advertisers, retailers or third parties that advertise their
        products / services on the Platform or with governmental authorities or
        third party service providers.{" "}
      </p>
  
      <p>
        f. When you voluntarily send us electronic mail, we will keep a record
        of this information so that we can respond to you. We only collect
        information from you when you register on our site or fill out a form.
        Also, when filling out a form on our site, you may be asked to enter
        your: name, e-mail address or phone number. You may, however, visit our
        site anonymously. In case you have submitted your personal information
        and contact details, we reserve the rights to Call, SMS, Email or
        WhatsApp about our products and offers, even if your number has DND
        activated on it.
      </p>
      <p>
        <b>g. Sharing of information with third parties :</b>
        <ul class="list-1">
          <li>
            Mavex Mentr takes your privacy very seriously. However, there may be
            times when we need to disclose your personal information to third
            parties and by accepting to use the Platform you agree with this.
          </li>
          <li>
            Mavex Mentr reserves the right to share recordings of all phone calls
            made between: [1] any User and Mavex Mentr; [2] any business Partner and
            Mavex Mentr; [3] any User and business Partner of Mavex Mentr, to legal,
            accounting or other advisors that provide professional services and
            / or who assist us in training, quality monitoring and quality
            enhancement of our services to the user and / or business partners.
            You hereby grant your irrevocable consent to Mavex Mentr to share such
            call recordings to third parties for such purposes. You further
            disclaim and / or abandon any right to sue Mavex Mentr in relation to
            the same.{" "}
          </li>
          <li>
            We may share personal information with corporate entities and
            affiliates. These entities and affiliates may market to you as a
            result of such sharing unless you explicitly opt-out.
          </li>
          <li>
            We may disclose personal information to third parties. This
            disclosure may be required for us to provide you access to our
            Services, to comply with our legal obligations, to enforce our User
            Agreement, to facilitate our marketing and advertising activities,
            or to prevent, detect, mitigate, and investigate fraudulent or
            illegal activities related to our Services. We do not disclose your
            personal information to third parties for their marketing and
            advertising purposes without your explicit consent.
          </li>
          <li>
            We may disclose personal information if required to do so by law or
            in the good faith belief that such disclosure is reasonably
            necessary to respond to court summons / notices, court orders, or
            other legal process. We may disclose personal information to law
            enforcement officers, third party rights owners, or others in the
            good faith belief that such disclosure is reasonably necessary to:
            enforce our Terms or Privacy Policy; respond to claims that an
            advertisement, posting or other content violates the rights of a
            third party / User; or protect the rights, property or personal
            safety of our Users or the general public.
          </li>
        </ul>
      </p>
      <br />
      <h4>IV. Safety guidelines</h4>
      <p>
        Mavex Mentr is always reaching out to a wider audience by showcasing the
        best of our user’s contributions. However, in doing so, we will not
        allow to be uploaded, published or posted anything on the Platform or
        website that, in our discretion, we think could be potentially
        endangering or inflammatory or embarrassing to our Users. The following
        are pointers to be kept in mind by users in the interests of their
        safety online and on the Platform :{" "}
      </p>
      <p>
        a. Users are solely liable for the security of their passwords. No
        administrator at Mavex Mentr will have knowledge of your password. It is
        important for you to protect against unauthorized access to your
        password, your computer and your mobile phone. Be sure to log off from
        the Platform when finished. Mavex Mentr does not undertake any liability for
        any unauthorised use of your account and password. If you suspect any
        unauthorized use of your account, you must immediately notify Mavex Mentr by
        sending an email to .{" "}
      </p>
      <p>
        b. All Users are responsible for the safety of their personal
        information. We strongly recommend that users do not disclose their
        physical addresses, email addresses, telephone numbers or other personal
        information online for public viewing.{" "}
      </p>
      <p>
        c. All Users need to be aware that if they upload their photos on the
        Platform, these photos can also be downloaded, since any image that can
        be displayed on a computer screen can be saved by the person viewing it.
        It is technically not possible to prevent this and hence Mavex Mentr cannot
        be held liable in such cases. If you are worried about someone else
        viewing or saving your images then please do not submit them. If our
        business partner asks for a photo or image in furtherance of any
        communication between you and the partner, you may in your discretion,
        send them such photo personally and not through the Platform.
      </p>
      <p>
        d. Do not post chain letters, promote pyramid schemes or recruit other
        Users for any network marketing or multilevel marketing businesses.{" "}
      </p>
      <p>
        e. Do not recruit other Users to join another website forum or chat
        group, whether personal or otherwise.{" "}
      </p>
      <p>
        f. All Users are reminded that they may be held legally accountable for
        what they say or do online. In particular, Users may be held liable for
        any defamatory comments, threats and / or untrue statements or other
        illegal and fraudulent claims made by them.
      </p>
      <br />
      <h4>V. MISCELLANEOUS</h4>
      <p>
        <b>a. CHILDREN’S PRIVACY</b>
        <br />
        Our Services are for any person above the age of 12 years although those
        under the age of 18 years must be supervised by parent/legal guardian
        throughout the process/session. We do not knowingly collect personally
        identifiable information from children or persons under the age of 18
        without the consent of their parents/legal guardian. In case we discover
        that a child under 18 has provided us with personal information, we
        immediately notify them either to seek consent from their parent/legal
        guardian or delete the data. If the user fails to abide by the
        instruction, we delete the data from our servers after the stipulated
        time period. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be able to take the necessary action.
      </p>
      <p>
        <b>b. Links to Other Sites</b>
        <br />
        Our Website may provide links to other websites that may collect
        personally identifiable information about you. Mavex Mentr is not
        responsible for the privacy practices or the content of such third party
        website and strongly advises that you review or go through the privacy
        policy of such websites before continuing to access them.
      </p>
      <p>
        <b>c. Security Precautions</b>
        <br />
        Our Website and Platform have stringent security measures in place to
        protect the loss, misuse, and alteration of the information under our
        control. Whenever you change or access your account information, we
        offer the use of a secure server. Once your information is in our
        possession we adhere to strict security guidelines, protecting it
        against unauthorized access. We use reasonable organizational,
        technical, and administrative measures to protect personal information
        under our control. Unfortunately, no data transmission over the Internet
        or data storage system can be guaranteed to be 100% secure. If you have
        reason to believe that your interaction with us is no longer secure (for
        example, if you feel that the security of any account you have with us
        has been compromised), please immediately notify us of the problem by
        contacting us or e-mailing us at info@Mavex Mentr.com
      </p>
      <p>
        <b>d. Advertisements on Mavex Mentr and its sub-domains</b>
        <br />
        We may use third-party advertising companies to serve ads when you visit
        our Website or Platform. These companies may use information (not
        including your name, address, email address, or telephone number) about
        your visits to this and other websites in order to provide
        advertisements about goods and services of interest to you.
      </p>
      <p>
        <b>e. Your Consent : </b>
        <br />
        By using the Platform and/ or by providing your information, You consent
        to the collection and use of the information You disclose on the Website
        or Platform in accordance with this Privacy Policy, including but not
        limited to Your consent for sharing your information as per this privacy
        policy.
      </p>
      <p>
        <b>f. Changes to this privacy policy :</b>
        <br />
        As and when there is any amendment to this policy, you will be asked to
        accept the amended policy if you wish to continue to use the Platform
        and only after your acceptance you could continue to use the Platform.
      </p>
      <p>
        <b>g. Grievance Officer</b>
        <br />
        In accordance with Information Technology Act 2000 and rules made there
        under, the name and contact details of the Grievance Officer are
        provided below:
      </p>
      <br />
      <p>
        CEO
        <br />
        Mavex Mentr
        <br />
        connect@mavexmentr.com
      </p>
    </div>
    </>
  );
}

export default Privacy;
