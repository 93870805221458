import { Routes, Route } from "react-router-dom";
import "./App.css";

import LayoutCard from "./ui/layout-card";
import Privacy from "./components/Privacy";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LayoutCard />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <a
        href="https://wa.me/7902237000"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
}

export default App;
