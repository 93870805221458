import React from "react";
import classes from "../modules/TimelineCard.module.css";

function CardView(props) {
  const cardStyle = {
    backgroundColor: props.bg
  };
  return (
    <div className={classes.card}>
      <div className={classes.notificationCard} style={cardStyle}>
        <img src={props.img} alt="Image" className={classes.bottomImg} />
        <p className={classes.notificationHeading}>{props.title}</p>

        <p className={classes.notificationPara}>{props.body}</p>
      
      
      </div>
    </div>
  );
}

export default CardView;
