import { Link } from 'react-router-dom';
import classes from '../modules/Hero.module.css';
import { scroller } from 'react-scroll';

export default function HeroArea() {

  const onExploreMoreClicked = () => {
    scroller.scrollTo('targetElement', {
      duration: 500, // Scroll duration in milliseconds
      smooth: 'easeInOutQuint', // Easing function for smooth scrolling
    });
  };

  return (
    <div>
      <div className={classes.herobox}>
        <div className={classes.herodetails}>
          <h1 className={classes.heroheading}>ELEVATE YOUR <span className={classes.spanedtext}>CAREER</span> WITH MAVEX MENTR<br></br></h1>
          <p  className={classes.herodesc}>With a keen ear of your choices and preferances, Mavex mentr's counselling experience is so seamless that you will land in your dream university even before you do.</p>
          <div  className={classes.btnbox}>
            <Link href="#"  className={classes.ctabtn} onClick={onExploreMoreClicked}>
              Explore more
            </Link>
      
          </div>
        </div>
        <div  className={classes.heroimage} >
          <img src="/assets/image.png" alt="mavex mentr" />
        </div>
      </div>
    </div>
  );
}

