import classes from "../modules/Faq.module.css";
import { useState } from "react";
import Accordian from "../ui/Accordian";
import FaqData from "../models/FaqData";
function Faq() {
  const [data] = useState(FaqData);
  const [show, setShow] = useState(-1);
  
  const handleAccordian = accordianNumber=>{
    setShow(show === accordianNumber ? null : accordianNumber)
 }
  return (
    <div className={classes.container}>
      <div className={classes.leftcontainer}>
        <img src="/assets/faqavatar2.jpg" alt="Image" className={classes.image} />
      </div>
      <div className={classes.rightcontainer}>
        <div className={classes.textcontainer}>
          <p className={classes.desc}>FAQ</p>
          <h1 className={classes.title}>
            We don't
            <span className={classes.spanedtext}> alienate</span> your concerns
            & queries<br></br>
          </h1>

          <div className={classes.accordiancontainer}>
            {data.map((faq,index) => {

              return (
                <Accordian 
                  key={faq.id}
                  show={show}
                  setShow={setShow}
                  index = {index}
                  handleAccordian = {handleAccordian}
                  question={faq.question}
                  answer={faq.answer}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
