import classes from "../modules/EnquiryForm.module.css";
import { TextField, Button, Stack, Chip } from "@mui/material";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Element } from "react-scroll";
import { useMutation } from "react-query";
import { submitEnquiry } from "../apis/ApiServices";
import enquiryRequestModel from "../models/EnquiryRequestModel";
import toast, { Toaster } from "react-hot-toast";

function EnquiryForm() {

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setphoneNumberError] = useState(false);

  // const mutation = useMutation((enquiryInfo) => {
  //   submitEnquiry(enquiryInfo);
  // });

  const mutation = useMutation(submitEnquiry, {
    // ... (other options)
  
    // Pass enquiryData as an argument to mutation.mutate
    onMutate: (enquiryInfo) => {
      // Optionally, you can perform actions before the mutation
      // For example, you can show a loading state here.
      // submitEnquiry(enquiryInfo)
    },
    onSuccess: (data, variables, context) => {
      // Access the response data here (data)
     
      toast.success(data.responsedescription);
      resetFields();
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });


  const handleSubmit = (event) => {
    event.preventDefault();

    setNameError(false);
    setEmailError(false);
    setphoneNumberError(false);

    if (name === "") {
      setNameError(true);
    }
    if (email === "") {
      setEmailError(true);
    }
    if (phoneNumber === "") {
      setphoneNumberError(true);
    }

    if (name && email && phoneNumber) {
      const enquiryData = enquiryRequestModel(name, email, phoneNumber, 1);
      // mutation.mutate({ enquiryData });
      mutation.mutate(enquiryData);
    }
  };
  const resetFields = () => {
    // Clear the form fields after a successful submission
    setName("");
    setEmail("");
    setPhoneNumber("");
    setSelectedChips([]); // Clear selected chips
    mutation.reset(); // Reset the mutation state
  };
  const [selectedChips, setSelectedChips] = useState([]); // State to keep track of selected chips

  const handleChipClick = (label) => {
    if (selectedChips.includes(label)) {
      // Chip is already selected, so remove it from the selectedChips array
      setSelectedChips(selectedChips.filter((chip) => chip !== label));
    } else {
      // Chip is not selected, so add it to the selectedChips array
      setSelectedChips([...selectedChips, label]);
    }
  };

  return (
    <Element name="targetElement">
      <div id="enquiry" className={classes.container}>
        <div className={classes.leftcontainer}>
          <img
            src="/assets/enquiry.jpg"
            alt="Aboutus"
            className={classes.image}
          />
        </div>
        <div className={classes.rightcontainer}>
          <div className={classes.textcontainer}>
            <p className={classes.desc}>Any specific question to ask?</p>
            <h1 className={classes.title}>
              Submit an
              <span className={classes.spanedtext}> enquiry</span> now !
              <br></br>
            </h1>
            <div className={classes.formdata}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  variant="outlined"
                  color="secondary"
                  type="name"
                  sx={{ mb: 3 }}
                  fullWidth
                  value={name}
                  error={nameError}
                />
                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                  <TextField
                    label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="email"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={email}
                    error={emailError}
                  />
                  <TextField
                    label="Mobile"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="phone"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={phoneNumber}
                    error={phoneNumberError}
                  />
                </Stack>
                <p className={classes.chiptitle}>
                  Choose your preffered country for studies
                </p>
                <Stack
                  className={classes.chips}
                  spacing={2}
                  direction="row"
                  sx={{ marginBottom: 4 }}
                >
                  <Chip
                    avatar={
                      <Avatar
                        alt="ind"
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/510px-Flag_of_India.svg.png"
                      />
                    }
                    label="India"
                    variant={
                      selectedChips.includes("India") ? "filled" : "outlined"
                    } // Check if the chip is selected
                    onClick={() => handleChipClick("India")}
                  />
                  <Chip
                    avatar={
                      <Avatar
                        alt="aus"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Flag_of_Australia_%28converted%29.svg/510px-Flag_of_Australia_%28converted%29.svg.png"
                      />
                    }
                    label="Australia"
                    variant={
                      selectedChips.includes("Australia")
                        ? "filled"
                        : "outlined"
                    } // Check if the chip is selected
                    onClick={() => handleChipClick("Australia")}
                  />
                  <Chip
                    avatar={
                      <Avatar
                        alt="uk"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/510px-Flag_of_the_United_Kingdom_%283-5%29.svg.png"
                      />
                    }
                    label="UK"
                    variant={
                      selectedChips.includes("UK") ? "filled" : "outlined"
                    } // Check if the chip is selected
                    onClick={() => handleChipClick("UK")}
                  />
                  <Chip
                    avatar={
                      <Avatar
                        alt="usa"
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/510px-Flag_of_the_United_States.svg.png"
                      />
                    }
                    label="USA"
                    variant={
                      selectedChips.includes("USA") ? "filled" : "outlined"
                    } // Check if the chip is selected
                    onClick={() => handleChipClick("USA")}
                  />
                  <Chip
                    avatar={
                      <Avatar
                        alt="canada"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/510px-Flag_of_Canada_%28Pantone%29.svg.png"
                      />
                    }
                    label="Canada"
                    variant={
                      selectedChips.includes("Canada") ? "filled" : "outlined"
                    } // Check if the chip is selected
                    onClick={() => handleChipClick("Canada")}
                  />
                  <Chip
                    avatar={
                      <Avatar
                        alt="other"
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1200px-Flag_of_Germany.svg.png"
                      />
                    }
                    label="Germany"
                    variant={
                      selectedChips.includes("Germany") ? "filled" : "outlined"
                    } // Check if the chip is selected
                    onClick={() => handleChipClick("Germany")}
                  />
                  <Chip
                    avatar={<Avatar alt="Others" />}
                    label="Other"
                    variant={
                      selectedChips.includes("Other") ? "filled" : "outlined"
                    } // Check if the chip is selected
                    onClick={() => handleChipClick("Other")}
                  />
                </Stack>

                <Button
                  className={classes.submitbtn}
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  disabled={mutation.isLoading}
                >
                  {mutation.isLoading ? "In Progress..." : "Enquire Now"}
                </Button>
              </form>
            </div>
          </div>
        </div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    </Element>
  );
}

export default EnquiryForm;
