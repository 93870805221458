import { useState, useEffect } from "react";
import axios from "axios";

import classes from "../modules/TopCountries.module.css";
import TopCountryCard from "../ui/TopCountryCard";

function TopCountries() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //write api call here
      try {
        const response = await axios.get(
          "https://api.learnyn.in/country/getall"
        );
        const filteredData = response.data.responsedata.slice(0, 10);
        setCountries(filteredData);
      } catch (err) {
        console.error("Error in retreiving country call :" + err);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="Country" className={classes.container}>
      <div className={classes.innertopcontainer}>
        <div>
          <p>TOP COUNTRIES</p>
          <div className={classes.titlehead}>
          <h1 >
            Browse <span className={classes.highlite}>courses</span> by country
          </h1>
          </div>
          <p>Our process to get on board</p>
        </div>
      </div>
      <div className={classes.innercontainer}>
        {countries.map((country) => (
          <TopCountryCard countryObj={country} />
        ))}
      </div>
    </div>
  );
}

export default TopCountries;
