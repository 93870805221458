import classes from "../modules/FooterAnimation.module.css";
function FooterAnimation() {
  return (
    <div className={classes.sky}>
      <div className={classes.city}></div>
      <div className={classes.flight}>
        <img src="/flight.png" alt="Flight" />
      </div>
    </div>
  );
}

export default FooterAnimation;
