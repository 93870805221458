import { useState, useEffect } from "react";
import axios from "axios";
import classes from "../modules/Reels.module.css";
import ImageItem from "../ui/ImageItem";

function Reels() {
  const [reelsList, setReels] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      //write api call here
      try {
        const response = await axios.get(
          "https://api.learnyn.in/banner/shortvideo"
        );
        setReels(response.data.responsedata);
      } catch (err) {
        console.error("Error in retreiving Reels call :" + err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      if (window.YT && window.YT.Player) {
        createPlayer(selectedVideo.shortsid);
      }
    }
  }, [selectedVideo]);

  const handleVideoClick = (videoData) => {
    setSelectedVideo(videoData);
  };

  const closeVideoPlayer = () => {
    if (player) {
      player.destroy();
    }
    setSelectedVideo(null);
  };

  const createPlayer = (videoId) => {
    if (window.YT && window.YT.Player) {
      setPlayer(
        new window.YT.Player("youtube-player", {
          height: "300",
          width: "100%",
          videoId,
        })
      );
    }
  };
  return (
    <div id="videos" className={classes.gallery}>
      <div className={classes.innertopcontainer}>
        <div>
          <p>Youtube Videos</p>
          <h1>
            Browse our short <span className={classes.highlite}>videos</span> on
            youtube
          </h1>
          <p>Our students testimonials</p>
        </div>
      </div>
      <div className={classes.imageGrid}>
        {reelsList.map((reels, index) => (
          <ImageItem
            key={index}
            image={reels}
            span={index % 3 === 0 ? 1 : 2}
            onClick={handleVideoClick}
          />
        ))}
      </div>
      {selectedVideo && (
        <div className={classes.videoModal}>
          <div id="youtube-player"></div>
          <button onClick={closeVideoPlayer}>Close</button>
        </div>
      )}
    </div>
  );
}

export default Reels;
