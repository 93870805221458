
import classes from "../modules/Accordian.module.css";


function Accordian(props) {
  return (
    <div className={classes.box}>
      <div className={classes.questicon}>
     
        <div className={classes.questionAnswerDiv}>
          <h3 onClick={() => props.handleAccordian(props.index)}>
            {props.question}
          </h3>
          {props.show === props.index ? <p>{props.answer}</p> : null}
        </div>
      </div>
    </div>
  );
}
export default Accordian;
