import classes from "../modules/TopUniversity.module.css";

function UniversityCard(props) {
  const obj = props.universityObj;
  return (
    <div className={classes.card}>
       <img
        src={obj.image}
        alt="Image"
        className={classes.cimage}
      />
      <h1 className={classes.title}>{obj.name}</h1>
    </div>
  );
}

export default UniversityCard;
