import { Link } from "react-router-dom";
import classes from "../modules/Footer.module.css";
import { Link as ScrollLink } from "react-scroll";
import AboutUs from "./AboutUs";
function Footer() {
  return (
    <>
      <div id="contactUs" className={classes.footer}>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.footerCol}>
             
             
                <div className={classes.imagesection}>
                  <img
                    className={classes.logo}
                    src="whitelogo.svg"
                    alt="hero image"
                  />
                </div>
           
            </div>
            <div className={classes.footerCol}>
              <h4>About Us</h4>
              <ul>
                <li>
                <li>
                  <ScrollLink className={classes.links} to="ourProcess" smooth={true} duration={500}>
                    Our Process
                  </ScrollLink>
                </li>
                </li>
                <li>
                  <ScrollLink className={classes.links} to="aboutUs" smooth={true} duration={500}>
                    Who We Are?
                  </ScrollLink>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className={classes.footerCol}>
              <h4>Contact us</h4>
              <ul>
               
                <li>
                  <Link to="https://wa.me/7902237000">Chat Support</Link>
                </li>
                <li>
                  <Link to="">+91 7902 237 000</Link>
                </li>
                <li>
                  <Link to="">connect@mavexmentr.com</Link>
                </li>
              </ul>
            </div>
            <div className={classes.footerCol}>
              <h4>Our Presence</h4>
              <ul>
                <li>
                  <Link to="">Metropillar No. 328,</Link>
                </li>
                <li>
                  <Link to="">Pathicheril Enclave, 2A</Link>
                </li>
                <li>
                  <Link to="">Changampuzha Nagar, Kochi</Link>
                </li>
                <li>
                  <Link to="">Kerala 682033</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
       
      </div>
      <div className={classes.powered}>
        <h3>Powered by <span>Teqbae </span> Innovations & Technologies</h3>
        </div>
    </>
  );
}

export default Footer;
