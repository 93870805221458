import React from 'react';
import classes from "../modules/Reels.module.css";

const ImageItem = ({ image, span, onClick }) => {
  const imageStyle = {
    backgroundImage: `url(${image.thumbnail})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const handleClick = () => {
    onClick(image);
  };
  
  return (
    <div className={`${classes.imageItem} ${span === 1 ? classes.double : ''}`}
    onClick={handleClick}>
      
      <div className={classes.imageContainer} style={imageStyle}></div>
      
    </div>
  );
};

export default ImageItem;
