import classes from "../modules/TopCountries.module.css";

function TopCountryCard(props) {
  const obj = props.countryObj;
  return (
    <div className={classes.wrapper}>
      <div className={classes.card}>
        <img src={obj.image} alt="Image" className={classes.cimage} />
        <h1 className={classes.title}>{obj.name}</h1>
      </div>
    </div>
  );
}

export default TopCountryCard;
