import React, { useState } from 'react';

const TruncatedParagraph = ({ text, maxWords }) => {
  const words = text.split(' ');
  const isTruncated = words.length > maxWords;
  const truncatedText = isTruncated ? words.slice(0, maxWords).join(' ') : text;
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <p>{expanded ? text : truncatedText}</p>
      {isTruncated && (
        <button className='textbutton' onClick={toggleExpansion}>
          {expanded ? 'Read less' : 'Read more'}
        </button>
      )}
    </div>
  );
};

export default TruncatedParagraph;
